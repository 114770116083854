



































































































import { Source } from "@/models";
import Search from "@/components/VEntrySearch.vue";

import { Vue, Component, Mixins, Watch, Ref } from "vue-property-decorator";
import { namespace } from "vuex-class";
import ShipmentsMixin from "@/mixins/http/ShipmentsMixin";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import EmptyView from "@/components/EmptyView.vue";
import StoreSubscriber from "@/store/StoreSubscriber";
import DataTable from "@monade/vue-components/src/components/DataTable.vue";
import VPageContentSticky from "@/components/VPageContentSticky.vue";
import { Shipment } from "@/models";
import { ShippingContract } from "@/models";
import { easync } from "@/utils/http";
import { sourceStore } from "@/store/typed";
import moment from "moment";
import { showToast } from "@/utils/Toast";

const getTag = "get_warehouse_items";
const destroyTag = "destroy_warehouse_item";

@Component({
  components: {
    Search,
    EmptyView,
    VPageContentSticky,
    DataTable,
  },
})
export default class ShipmentList extends Mixins(ShipmentsMixin) {
  @RegisterHttp(getTag) getRequest: RequestData;
  @RegisterHttp(destroyTag) destroyRequest: RequestData;
  @Ref() editModal: HTMLDivElement;
  @Ref() confirmModal: HTMLDivElement;

  items: Shipment[] = [];
  page = 1;

  trackingTriggered = false;

  columns = [
    { name: "trackingCode", label: "Codice tracking" },
    { name: "orderExternalId", label: "Numero ordine" },
    { name: "trackingUrl", label: "Link tracking" },
    { name: "status", label: "Status" },
  ];

  search: string | null = null;

  created() {
    this.subscribe();
  }

  carrierLogo(item: ShippingContract) {
    const carrier = this.carrier(item);
    return require(`../../assets/images/${carrier.code}.png`);
  }

  carrierName(item: ShippingContract) {
    return this.carrier(item)?.name;
  }

  carrier(item: ShippingContract) {
    return ShippingContract.carriers.find((o) => item.type === o.id);
  }

  onCreated(item: Shipment) {
    this.items.unshift(item);
  }

  async launchTrackingScript() {
    window.localStorage.setItem(
      "last_tracking_script",
      moment(new Date()).format("DD/MM/YYYY HH:mm")
    );
    showToast("Script partito", { duration: 10000 });
    const sourceId = sourceStore.currentSource?.id || null;
    this.trackingTriggered = true;
    const [data, errors] = await easync(this.trackAllShipments(sourceId));
  }

  get lastTrackingScript() {
    return window.localStorage.getItem("last_tracking_script");
  }

  onUpdated(item: Shipment) {
    const index = this.items.findIndex((o) => item.id === o.id);
    Vue.set(this.items, index, item);
  }

  async searchItems(name: string) {
    if (name.trim().length > 0) {
      this.search = name;
      try {
        const items = await this.getShipments(this.payload, getTag);
        return items;
      } catch (e) {
        console.error(e);
        return [];
      }
    }
  }

  onSearchCanceled() {
    this.page = 1;
    this.search = null;
    this.loadItems();
  }

  onSearched(items: { items: Shipment[] }) {
    this.page = 1;
    this.items = items.items;
  }

  @Watch("page")
  onPageChanged(value: any, oldValue: any) {
    if (value == 1 && oldValue) {
      this.items = [];
    }
    this.loadItems();
  }

  mounted() {
    if (sourceStore.currentSource) {
      this.loadItems();
    }
  }

  subscribe() {
    StoreSubscriber.subscribe("source/setCurrentSource", this.onSourceSet);
  }

  onSourceSet(source: Source | undefined) {
    if (source) {
      this.loadItems();
    }
  }

  nextPage() {
    this.page += 1;
  }

  get payload() {
    const data: any = {
      per: 20,
      page: this.page,
      filter: {
        source: sourceStore.currentSource?.id,
      },
    };

    if (this.search) {
      data.filter.term = this.search;
    }

    return data;
  }

  async loadItems() {
    const [data, errors] = await easync(
      this.getShipments(this.payload, getTag)
    );
    if (errors) {
      if (this.page > 1) {
        this.page -= 1;
      }
      return;
    }

    if (this.page === 1) {
      this.items = [];
    }
    this.items.push(...data);
  }

  get loading() {
    return this.getRequest?.loading;
  }

  get meta() {
    return this.getRequest?.meta;
  }
}
