var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"page-content"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 col-lg-4 col-md-4"},[_c('search',{staticClass:"my-auto",attrs:{"load":_vm.searchItems,"placeholder":"Cerca.."},on:{"searched":_vm.onSearched,"cancel":_vm.onSearchCanceled}})],1),_c('div',{staticClass:"col-6 col-lg-8 col-md-8 pl-3 text-right"},[_c('button',{staticClass:" btn btn-primary",attrs:{"disabled":_vm.trackingTriggered},on:{"click":_vm.launchTrackingScript}},[_vm._v(" Lancia script tracking ")]),_c('p',{staticClass:"text-muted"},[_c('small',[_vm._v("Ultima esecuzione: "+_vm._s(_vm.lastTrackingScript||'Mai'))])])])]),_c('data-table',{attrs:{"columns":_vm.columns,"values":_vm.items,"loading":_vm.loading},scopedSlots:_vm._u([{key:"column_trackingCode",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"d-flex"},[_c('img',{staticClass:"logo my-auto",attrs:{"src":_vm.carrierLogo(value.shippingContract)}}),_c('p',{staticClass:"my-auto font-weight-bold my-auto ml-2"},[_vm._v(" "+_vm._s(value.trackingCode || "-")+" ")])])]}},{key:"column_orderExternalId",fn:function(ref){
var value = ref.value;
return [_c('router-link',{staticClass:"my-auto font-weight-bold my-auto ml-2",attrs:{"to":{ name: 'orders_show', params: { id: value.order.id } }}},[_vm._v(" "+_vm._s(value.order.externalId)+" ")])]}},{key:"column_status",fn:function(ref){
var value = ref.value;
return [_c('p',{staticClass:"my-auto badge",class:['badge-' + value.statusStyle]},[_vm._v(" "+_vm._s(value.formattedStatus)+" ")])]}},{key:"no-results-label",fn:function(){return [(_vm.items && _vm.items.length == 0 && !_vm.loading)?_c('empty-view',{attrs:{"primaryText":"Non c'è nessuna spedizione qui","secondaryText":"Qui potrai tenere sotto controllo le spedizioni dei clienti"}}):_vm._e()]},proxy:true},{key:"column_trackingUrl",fn:function(ref){
var value = ref.value;
return [(value.trackingCode)?_c('a',{staticClass:"my-auto font-weight-light",attrs:{"href":value.trackingUrl,"target":"_blank"}},[_vm._v("Link tracking")]):_c('p',{staticClass:"my-auto text-muted"},[_vm._v("-")])]}},{key:"loading",fn:function(){return [(_vm.loading)?_c('div',{staticClass:"page-content p-5 d-flex w-100 my-5"},[_c('fa-icon',{staticClass:"text-primary m-auto",attrs:{"icon":"circle-notch","size":"3x","spin":""}})],1):_vm._e()]},proxy:true}])}),(_vm.meta)?_c('div',{staticClass:"d-flex"},[(_vm.page < _vm.meta.totalPages)?_c('button',{staticClass:"btn btn-outline-primary mx-auto",on:{"click":function($event){return _vm.nextPage()}}},[_vm._v(" Carica altri ")]):_vm._e()]):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"page-subtitle text-muted text-uppercase"},[_vm._v("Lista")])])}]

export { render, staticRenderFns }